import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../Banner/BannerInfo";
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        const navigate = useNavigate();
        return <Component {...props} params={params} navigate={navigate} />;
    };
};

class BoardDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
        };
    }

    componentDidMount() {
        const { id } = this.props.params;
        const { apiEndpoint } = this.props;
        axios.get(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/${id}/`)
            .then(response => {
                const post = response.data;
                this.updateImageUrls(post).then(updatedContent => {
                    post.content = updatedContent;
                    this.setState({ post });
                });
            })
            .catch(error => {
                console.error('There was an error fetching the post!', error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.urlRefreshInterval);
    }

    updateImageUrls = async (post) => {
        const imageRegex = /!\[.*\]\((.*)\)/g;
        const matches = [...post.content.matchAll(imageRegex)];
        const updatedContentPromises = matches.map(async (match) => {
            const originalUrl = match[1];
            const key = originalUrl.split('/').slice(-1)[0].split('?')[0];
            const { apiMinioBucket } = this.props; // 확인필요
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${apiMinioBucket}/${key}/`);
                return { originalUrl, signedUrl: response.data.url };
            } catch (error) {
                console.error('Error fetching signed URL:', error);
                return { originalUrl, signedUrl: originalUrl };
            }
        });

        const updatedUrls = await Promise.all(updatedContentPromises);
        let updatedContent = post.content;
        updatedUrls.forEach(({ originalUrl, signedUrl }) => {
            updatedContent = updatedContent.replace(originalUrl, signedUrl);
        });
        return updatedContent;
    }

    handleDelete = () => {
        const { id } = this.props.params;
        const { apiEndpoint, listPath } = this.props;

        axios.delete(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/${id}/`)
            .then(() => {
                this.props.navigate(`/${listPath}`);
            })
            .catch(error => {
                console.error('There was an error deleting the post!', error);
            });
    }

    render() {
        const { post } = this.state;
        const { app_title, app_desc, listPath, formPath, isAuthenticated } = this.props; // this.props로 접근하도록 수정

        if (!post) return <div>Loading...</div>;

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title={app_title} header_className="posts_background py-5" desc={app_desc}></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Card className="mb-4">
                                <Card.Header as="h2">{post.title}</Card.Header>
                                <Card.Subtitle className="mb-2 text-muted bg-light p-2">
                                    {post.tags && post.tags.length > 0 && (
                                        <div className="mt-3">
                                            <div className="d-flex flex-wrap">
                                                {post.tags.map((tag, index) => (
                                                    <span key={index} className="badge tag-color me-2 mb-2">{tag}</span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Card.Subtitle>
                                <Card.Body>
                                    <Card.Text>
                                        <ReactMarkdown
                                            components={{
                                                code({ node, inline, className, children, ...props }) {
                                                    const match = /language-(\w+)/.exec(className || '');
                                                    return !inline && match ? (
                                                        <SyntaxHighlighter
                                                            style={atomDark}
                                                            language={match[1]}
                                                            PreTag="div"
                                                            {...props}
                                                        >
                                                            {String(children).replace(/\n$/, '')}
                                                        </SyntaxHighlighter>
                                                    ) : (
                                                        <code className={className} {...props}>
                                                            {children}
                                                        </code>
                                                    );
                                                },
                                                blockquote({ node, ...props }) { // 인용문 스타일 수정
                                                    return (
                                                        <blockquote
                                                            style={{
                                                                borderLeft: '4px solid #ccc',
                                                                paddingLeft: '1em',
                                                                color: '#6c757d',
                                                            }}
                                                            {...props}
                                                        />
                                                    );
                                                },
                                                img({ node, ...props }) { // 이미지 스타일 수정
                                                    return <img style={{ maxWidth: '100%' }} {...props} alt="" />;
                                                }
                                            }}
                                        >
                                            {post.content}
                                        </ReactMarkdown>
                                    </Card.Text>
                                    <hr />
                                    <p className="text-muted">Created at: {new Date(post.created_at).toLocaleString()}</p>
                                    <p className="text-muted">Updated at: {new Date(post.updated_at).toLocaleString()}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={1}>
                            {isAuthenticated && (
                                <div className="fixed-actions"> {/* 고정된 액션 버튼 컨테이너 - 2024-08-07 */}
                                    <Link to={`/${listPath}`}>
                                        <Button variant="dark" className='w-100 mb-2'><i class="fa-solid fa-list"></i></Button> {/* 버튼 간격 조정 - 2024-08-07 */}
                                    </Link>
                                    <Link to={`/${formPath}`}>
                                        <Button variant="dark" className='w-100 mb-2'><i class="fa-solid fa-plus"></i></Button> {/* 버튼 간격 조정 - 2024-08-07 */}
                                    </Link>
                                    <Link to={`/${listPath}/${post.id}/edit`}> {/* 버튼 간격 조정 - 2024-08-07 */}
                                        <Button variant="dark" className='w-100 mb-2'><i class="fa-regular fa-pen-to-square"></i></Button>
                                    </Link>
                                    <Link>
                                        <Button variant="danger" onClick={this.handleDelete} className='w-100 mb-2'><i class="fa-solid fa-trash"></i></Button>
                                    </Link>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(BoardDetail);