import React, { Component } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression'; // 이미지 압축 라이브러리 추가 - 2024-08-11

const custom_map = {
    api_contents_list: 'items_list',
    api_minio_bucket: process.env.REACT_APP_minio_items_md_img, // 마크다운용
    api_minio_img_bucket: process.env.REACT_APP_minio_items_img, // 카드용
    contents_list_path: 'items',
    contents_form_path: 'items-reg',
};

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            errorMessage: ''
        };
    }

    handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedFile = await this.compressImage(file); // 이미지 압축 - 2024-08-11
                this.setState({ image: compressedFile });
            } catch (error) {
                console.error('Image compression error:', error);
                this.setState({ errorMessage: '이미지 압축 중 오류가 발생했습니다.' });
            }
        }
    };

    compressImage = async (file) => {
        // 이미지 압축 옵션 설정 - 2024-08-11
        const options = {
            maxSizeMB: 1, // 이미지 최대 크기 1MB로 제한
            maxWidthOrHeight: 380, // 이미지의 최대 너비 또는 높이 1024px로 제한
            useWebWorker: true,
        };
        return await imageCompression(file, options);
    };

    uploadImageToMinIO = async () => {
        const { image } = this.state;
        const { csrftoken } = this.props;
        console.log(custom_map.api_minio_img_bucket);

        if (image) {
            const uniqueFileName = this.generateUniqueFileName(image.name);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-upload-url/${custom_map.api_minio_img_bucket}/${uniqueFileName}/`);
                const uploadUrl = response.data.url;

                await axios.put(uploadUrl, image, {
                    headers: {
                        'Content-Type': image.type,
                        'X-CSRFToken': csrftoken
                    }
                });

                return { success: true, fileName: uniqueFileName }; // 파일명 반환 - 2024-08-11 2차 수정
            } catch (error) {
                console.error('Image upload error:', error);
                this.setState({ errorMessage: '이미지 업로드 중 오류가 발생했습니다.' });
                return { success: false, fileName: null }; // 파일명 null 반환 - 2024-08-11 2차 수정
            }
        }
        return { success: false, fileName: null }; // 이미지 파일이 없는 경우 - 2024-08-11 2차 수정
    };

    generateUniqueFileName = (originalName) => {
        const extension = originalName.split('.').pop();
        const uniqueName = `${uuidv4()}.${extension}`;
        return uniqueName;
    };

    render() {
        const { errorMessage } = this.state;

        return (
            <div>
                <Form.Group controlId="formImage">
                    <Form.Label>Image</Form.Label>
                    <Form.Control
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={this.handleFileChange}
                    />
                </Form.Group>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
            </div>
        );
    }
}

export default ImageUpload;
