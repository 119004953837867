import React, { Component } from 'react';
import axios from 'axios';
import Profile from "./Profile";
import ReactMarkdown from 'react-markdown';
import { Container, Card } from 'react-bootstrap';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "./about.css";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
            isLoading: true,
            error: null
        };
    }

    // 2024-07-28 - 1번 포스트 데이터 불러오기
    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/post_list/1/`)
            .then(response => {
                const post = response.data;
                this.updateImageUrls(post).then(updatedContent => {
                    post.content = updatedContent;
                    this.setState({ post, isLoading: false });
                });
            })
            .catch(error => {
                console.error('There was an error fetching the post!', error);
                this.setState({ error: error.message, isLoading: false });
            });
    }

    updateImageUrls = async (post) => {  // 2024-08-06: 이미지 URL 갱신 함수 추가
        const imageRegex = /!\[.*\]\((.*)\)/g;
        const matches = [...post.content.matchAll(imageRegex)];
        const updatedContentPromises = matches.map(async (match) => {
            const originalUrl = match[1];
            const key = originalUrl.split('/').slice(-1)[0].split('?')[0];
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/itstant-posts-md-img/${key}/`);
                return { originalUrl, signedUrl: response.data.url };
            } catch (error) {
                console.error('Error fetching signed URL:', error);
                return { originalUrl, signedUrl: originalUrl };
            }
        });

        const updatedUrls = await Promise.all(updatedContentPromises);
        let updatedContent = post.content;
        updatedUrls.forEach(({ originalUrl, signedUrl }) => {
            updatedContent = updatedContent.replace(originalUrl, signedUrl);
        });
        return updatedContent;
    }

    render() {
        const { post, isLoading } = this.state;

        return (
            <div className='about-bg'>
                <Container className="profile-section mt-5">
                    <Profile></Profile>
                    {!isLoading && post && (
                        <Card className='mt-4'>
                            <Card.Header as="h2">{post.title}</Card.Header>
                            <Card.Subtitle className="mb-2 text-muted bg-light p-2">{post.hook_text}</Card.Subtitle>
                            <Card.Body>
                                <Card.Text>
                                    <ReactMarkdown
                                        components={{
                                            code({ node, inline, className, children, ...props }) {
                                                const match = /language-(\w+)/.exec(className || '');
                                                return !inline && match ? (
                                                    <SyntaxHighlighter
                                                        style={atomDark}
                                                        language={match[1]}
                                                        PreTag="div"
                                                        {...props}
                                                    >
                                                        {String(children).replace(/\n$/, '')}
                                                    </SyntaxHighlighter>
                                                ) : (
                                                    <code className={className} {...props}>
                                                        {children}
                                                    </code>
                                                );
                                            },
                                            img({ node, ...props }) {
                                                return <img style={{ maxWidth: '100%' }} {...props} alt="" />;
                                            }
                                        }}
                                    >
                                        {post.content}
                                    </ReactMarkdown>
                                </Card.Text>
                                <hr />
                                <p className="text-muted">Created at: {new Date(post.created_at).toLocaleString()}</p>
                            </Card.Body>
                        </Card>
                    )}
                </Container>
            </div>
        );
    }
}

export default About;