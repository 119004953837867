import React, { Component } from "react";
import { Container } from 'react-bootstrap';

class FOOTER extends Component {
    render() {
        return (
            <footer className="py-3 bg-dark">
                <Container className="text-center text-white">
                    <div>Copyright &copy; icurfer 2024</div>
                </Container>
            </footer>
        );
    }
}

export default FOOTER;
