// src/components/PortfolioSection.js
import React, { Component } from 'react';
import './PortfolioSection.css';

class PortfolioSection extends Component {
  render() {
    return (
      <div className="portfolio">
        <h2>My Work</h2>
        <div className="portfolio-items">
          {/* 포트폴리오 아이템들을 여기에 추가 */}
          <div className="item">Project 1</div>
          <div className="item">Project 2</div>
          <div className="item">Project 3</div>
        </div>
      </div>
    );
  }
}

export default PortfolioSection;
