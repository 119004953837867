import React, { Component } from "react";

class ADMESSAGE extends Component {
    render() {
        return (
            <div className="container px-4 px-lg-5 mt-5">
                <div className="row gx-4 gx-lg-4  justify-content-center">
                    <div className="col mb-5 alert alert-secondary text-center small" role="alert">
                        쿠팡에서 물건을 구입하시면 운영자에게 소정의 수수료가 지급됩니다.
                    </div>
                </div>
            </div>
        );
    }
}

export default ADMESSAGE;


