// src/components/AboutSection.js
import React, { Component } from 'react';
import './AboutSection.css';

class AboutSection extends Component {
  render() {
    return (
      <div className="about">
        <h2>About Me</h2>
        <p>Brief introduction and information about you.</p>
      </div>
    );
  }
}

export default AboutSection;
