import React from "react";
import BoardList from '../../components/Board/BoardList';

const BoardPost = (props) => (
    <BoardList {...props} /> // props를 스프레드 문법으로 전달
);

// const BoardPost = ({ isAuthenticated }) => (
//     <BoardList
//         app_title="Dev History"
//         app_desc="React / Django"
//         apiEndpoint="post_list"
//         apiMinioBucket = 'itstant-posts-md-img'
//         listPath="posts"
//         formPath="posts-create"
//         itemsPerPage={12}
//         isAuthenticated={isAuthenticated}
//     />
// );

export default BoardPost;