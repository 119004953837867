import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../Banner/BannerInfo";
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';

const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        const navigate = useNavigate();
        return <Component {...props} params={params} navigate={navigate} />;
    };
};

class UrlDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
        };
    }

    componentDidMount() {
        const { id } = this.props.params;
        const { apiEndpoint } = this.props;

        axios.get(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/${id}/`)
            .then(response => {
                // 가져온 데이터를 post 상태로 저장
                this.setState({ post: response.data });
            })
            .catch(error => {
                console.error('There was an error fetching the post!', error);
            });
    }

    componentWillUnmount() {
        if (this.urlRefreshInterval) {
            clearInterval(this.urlRefreshInterval);
        }
    }

    handleDelete = () => {
        const { id } = this.props.params;
        const { apiEndpoint, listPath } = this.props;

        axios.delete(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/${id}/`)
            .then(() => {
                this.props.navigate(`/${listPath}`);
            })
            .catch(error => {
                console.error('There was an error deleting the post!', error);
            });
    }

    render() {
        const { post } = this.state;
        const { app_title, app_desc, listPath, formPath, isAuthenticated } = this.props;

        if (!post) return <div>Loading...</div>;

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title={app_title} header_className="posts_background py-5" desc={app_desc} />
                <Container className="flex-grow-1 mt-4">
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Card className="mb-4">
                                <Card.Header as="h2">{post.title}</Card.Header>
                                <Card.Subtitle className="mb-2 text-muted bg-light p-2">
                                    {post.tags && post.tags.length > 0 && (
                                        <div className="mt-3">
                                            <div className="d-flex flex-wrap">
                                                {post.tags.map((tag, index) => (
                                                    <span key={index} className="badge tag-color me-2 mb-2">{tag}</span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Card.Subtitle>
                                <Card.Body>
                                    <Card.Text>
                                        <strong>URL:</strong> <a href={post.url} target="_blank" rel="noopener noreferrer">{post.url}</a>
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Description:</strong> {post.desc}
                                    </Card.Text>
                                    <Card.Text>
                                        {post.content}
                                    </Card.Text>
                                    <hr />
                                    <p className="text-muted">Created at: {new Date(post.created_at).toLocaleString()}</p>
                                    <p className="text-muted">Updated at: {new Date(post.updated_at).toLocaleString()}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={1}>
                            {isAuthenticated && (
                                <div className="fixed-actions"> {/* 고정된 액션 버튼 컨테이너 - 2024-08-07 */}
                                    <Link to={`/${listPath}`}>
                                        <Button variant="dark" className='w-100 mb-2'><i className="fa-solid fa-list"></i></Button>
                                    </Link>
                                    <Link to={`/${formPath}`}>
                                        <Button variant="dark" className='w-100 mb-2'><i className="fa-solid fa-plus"></i></Button>
                                    </Link>
                                    <Link to={`/${listPath}/${post.id}/edit`}>
                                        <Button variant="dark" className='w-100 mb-2'><i className="fa-regular fa-pen-to-square"></i></Button>
                                    </Link>
                                    <Button variant="danger" onClick={this.handleDelete} className='w-100 mb-2'><i className="fa-solid fa-trash"></i></Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(UrlDetail);
