import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import ADMESSAGE from "./ADMESSAGE";
import { Container, Card, Button, Pagination, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
// import ReactMarkdown from 'react-markdown';

const custom_map = {
    api_contents_list: 'items_list',
    api_minio_bucket: process.env.REACT_APP_minio_items_md_img, // 마크다운용
    api_minio_img_bucket: process.env.REACT_APP_minio_items_img, // 카드용
    contents_list_path: 'items',
    contents_form_path: 'items-reg',
    board_contents_count: 12
};

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            currentPage: 1,
            totalPages: 1,
            searchQuery: "", // 검색어 상태 추가: 2024-07-29
        };
    }

    // 검색 기능 추가: 2024-07-29
    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    // 검색 기능 추가: 2024-07-29
    handleSearchSubmit = () => {
        this.fetchPosts(1, this.state.searchQuery);
    }

    // 엔터 키로 검색 동작 추가: 2024-07-30
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSearchSubmit();
        }
    }

    componentDidMount() {
        this.fetchPosts(this.state.currentPage);
    }

    fetchPosts(page, query = "") {
        axios.get(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/?page=${page}&search=${query}`)
            .then(async response => {
                const posts = response.data.results;

                // 각 게시물에 대해 presigned URL을 가져옴 - 2024-08-11
                const postsWithImages = await Promise.all(posts.map(async post => {
                    if (post.obs_path) {
                        try {
                            const imageUrlResponse = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${post.obs_path}/`);
                            post.imageUrl = imageUrlResponse.data.url;
                        } catch (error) {
                            console.error('Error fetching image URL:', error);
                            post.imageUrl = null; // 이미지가 없을 경우 null로 설정 - 2024-08-11
                        }
                    }
                    return post;
                }));

                this.setState({
                    posts: postsWithImages,
                    currentPage: page,
                    totalPages: Math.ceil(response.data.count / `${custom_map.board_contents_count}`),
                });
            })
            .catch(error => {
                console.error('There was an error fetching the blog posts!', error);
            });
    }

    handlePageChange = (page) => {
        this.fetchPosts(page);
    }

    truncateContent = (content) => {
        return content.length > 10 ? content.substring(0, 10) + "..." : content;
    }

    formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        const [month, day, year] = date.toLocaleDateString('en-US', options).split('/');
        return `${year}.${month}.${day}.`;
    }

    truncateText = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    }

    render() {
        const { posts, currentPage, totalPages, searchQuery } = this.state;
        const { isAuthenticated } = this.props;

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title="Items" desc="제휴마케팅 상품 페이지 입니다." header_className="posts_background py-5"></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row className="mb-2 justify-content-end">
                        <Col className="d-flex col-2">
                            <div className='input-group justify-content-end'>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                    onKeyDown={this.handleKeyPress} // 엔터 키 핸들러 추가: 2024-07-30
                                    placeholder="Search posts"
                                    className='form-control'
                                />

                                <Button onClick={this.handleSearchSubmit} variant="primary" className='btn-navy'><i class="fa-solid fa-magnifying-glass"></i></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {posts.map(post => (
                            <Col key={post.id} sm={12} md={6} lg={3} className="mb-4 d-flex align-items-stretch">
                                <Card style={{ width: '18rem' }} className="d-flex flex-column">
                                    {post.imageUrl && (
                                        <Card.Img className='img-fluid p-2' variant="top" src={post.imageUrl} alt={post.title} />
                                    )}
                                    <Card.Body className="d-flex flex-column">
                                        {/* 이미지추가 */}
                                        <Card.Title>{this.truncateText(post.title, 10)}</Card.Title>
                                        <Card.Text className="flex-grow-1">
                                            <ReactMarkdown>{post.content}</ReactMarkdown><br />
                                            {/* <ReactMarkdown>{this.truncateText(post.content, 10)}</ReactMarkdown><br /> */}
                                        </Card.Text>
                                        <div className="mt-auto">
                                            <Button variant="primary" as={Link} to={post.url}>View Details</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={4} className="d-flex justify-content-center">
                            <Pagination>
                                <Pagination.First onClick={() => this.handlePageChange(1)} disabled={currentPage === 1} />
                                <Pagination.Prev onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                {[...Array(totalPages)].map((_, index) => (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === currentPage}
                                        onClick={() => this.handlePageChange(index + 1)}
                                        className={index + 1 === currentPage ? 'btn-navy' : ''}  // 스타일 클래스 추가: 2024-07-31
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => this.handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                                <Pagination.Last onClick={() => this.handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                            </Pagination>
                        </Col>
                        <Col md={4} className="d-flex justify-content-end">
                            {isAuthenticated && (
                                <Link to={`/${custom_map.contents_form_path}`}>
                                    <Button variant="primary" className='btn-navy'><i class="fas fa-pen" aria-hidden="true"></i> Create Post</Button>
                                </Link>
                            )}
                        </Col>
                    </Row>
                </Container>
                <ADMESSAGE></ADMESSAGE>
            </div>
        );
    }
}

export default Items;