import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../Banner/BannerInfo";
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import 'react-markdown-editor-lite/lib/index.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // 2024-08-06 태그 기능을 위해 추가
import 'react-bootstrap-typeahead/css/Typeahead.css'; // 2024-08-06 태그 기능을 위해 추가

class UrlForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            url: '',
            desc: '',
            author: '', // 작성자 등록 - 2024-07-20
            tags: [], // 추가: 2024-08-06 - 태그 상태 추가
            errorMessage: '',
            redirect: false, // 리다이렉트를 위한 상태 추가
            csrftoken: '' // CSRF 토큰을 위한 상태 추가
        };
        this.editorInstance = React.createRef(); // 에디터 인스턴스를 참조하기 위한 ref 생성 - 2024-07-31
        this.tagInput = React.createRef(); // 태그 인풋을 참조하기 위한 ref 생성
    }

    // 수정된 부분: 컴포넌트가 마운트될 때 CSRF 토큰을 상태에 저장 - 2024-07-19
    componentDidMount() {
        this.setState({ csrftoken: this.getCookie('csrftoken') });
        this.getUserInfo(); // 컴포넌트가 마운트될 때 사용자 정보를 가져옴

        // "paste" 이벤트가 발생할 때 handlePaste라는 함수를 실행하도록 설정하는 이벤트 리스너 - 2024-07-24
        document.addEventListener('paste', this.handlePaste);
    }

    // "paste" 이벤트가 발생후 클린업 - 2024-07-24
    componentWillUnmount() {
        document.removeEventListener('paste', this.handlePaste);
    }

    // 수정된 부분: CSRF 토큰 가져옴.  - 2024-07-19
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    getUserInfo = () => {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/accounts/user-info/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                const user_id = response.data.user_id;
                this.setState({ author: user_id });
            })
            .catch(error => {
                console.error(error);
                this.setState({ errorMessage: '사용자 정보를 가져오는 중 오류가 발생했습니다.' });
            });
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleEditorChange = ({ text }) => {
        this.setState({ desc: text });
    };

    // [Begin] 2024-08-06 태그 기능을 위해 추가
    handleTagChange = (selected) => {
        this.setState({
            tags: selected.map(tag => tag.label),
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = e.target.value.trim();
            if (newTag) {
                this.setState(state => ({
                    tags: [...state.tags, newTag],
                }));
                this.tagInput.current.clear(); // 입력 필드 초기화
            }
        }
    }
    // [End] 2024-08-06 태그 기능을 위해 추가

    handleSubmit = (event) => {
        event.preventDefault();
        const { title, url, desc, author, tags } = this.state;
        const { apiEndpoint } = this.props;

        axios.post(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/`, {
            title,
            url,
            desc,
            author,
            tags, // 2024-08-06 태그 기능을 위해 추가
        }, {
            // 요청 헤더에 토큰을 추가 - 2024-07-20
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken // CSRF 토큰 포함
            }
        })
            .then(response => {
                console.log(response.data);
                // 성공적으로 포스트가 생성된 경우, 리다이렉트를 위한 상태 업데이트
                this.setState({ redirect: true });
            })
            .catch((error) => {
                // console.error('There was an error creating the post!', error.response ? error.response.data : error.message);
                this.setState({ errorMessage: 'Failed to create post.' });
            });
    };

    handleCancel = () => {
        this.setState({ redirect: true });
    }

    render() {
        const { title, url, desc, tags, errorMessage, redirect } = this.state;
        const { app_title, app_desc, listPath, isAuthenticated } = this.props; // this.props로 접근하도록 수정
        
        if (redirect) {
            // 리다이렉트 상태가 true인 경우 포스트 리스트 페이지로 리다이렉트
            return <Navigate to={`/${listPath}/`} />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title={app_title} header_className="posts_background py-5" desc={app_desc}></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={title}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formUrl">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="url"
                                value={url}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTags">
                            <Form.Label>Tags</Form.Label>
                            <Typeahead
                                id="tags"
                                multiple
                                allowNew
                                newSelectionPrefix="Add a new tag: "
                                options={[]}
                                selected={tags.map(tag => ({ label: tag }))}
                                onChange={this.handleTagChange}
                                onKeyDown={this.handleKeyDown} // 키 다운 이벤트 핸들러 추가
                                ref={this.tagInput} // ref 추가
                                placeholder="Add tags"
                            />
                        </Form.Group>
                        <Form.Group controlId="formDesc">
                            <Form.Label>Desc</Form.Label>
                            <Form.Control
                                type="text"
                                name="desc"
                                value={desc}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        {isAuthenticated && (
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="primary" type="submit" className='me-2'>
                                <i class="fas fa-pen" aria-hidden="true"></i> Create Post
                            </Button>
                            <Button variant="secondary" type="button" onClick={this.handleCancel}>
                                Cancel
                            </Button>
                        </div>
                        )}
                    </Form>
                    <br />
                </Container>
            </div>
        );
    }
}

export default UrlForm;