import React, { Component } from "react";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

class App extends Component {
    copyToClipboard = () => {
        const email = 'icurfer@gmail.com';
        navigator.clipboard.writeText(email).then(() => {
            alert('e-mail 주소가 복사되었습니다.');
        }).catch(err => {
            console.error('복사 실패:', err);
        });
    };

    render() {
        const projects = [
            {
                id: 1,
                title: "카카오톡 오픈 채팅",
                description: "서비스 문의, 견적 등 궁금한점을 문의해보세요.",
                type: "kakao",
                link: "https://open.kakao.com/me/icurfer"
            },
            {
                id: 2,
                title: "이메일 문의",
                description: "서비스 문의, 견적 등 궁금한점을 문의해보세요.",
                type: "e-mail",
                link: "icurfer@gmail.com",
            },
        ];

        return (
            <Container fluid className="py-5 min-vh-100">
                <Row className="justify-content-center">
                    <h1 className="pt-5 text-black text-center">Contact</h1>
                    <p className="text-center">상담을 원하시거나 문의 사항이 있으시면 아래 채널로 연락주세요.</p>
                    {projects.map(project => (
                        <Col key={project.id} md={6} className="mb-4 d-flex align-items-stretch justify-content-center">
                            <Card className="shadow-lg rounded" style={{ border: 'none', width: '100%', maxWidth: '500px' }}>
                                {/* 2024-09-09: 카드에 그림자, 테두리 제거, 중앙 정렬 */}
                                <Card.Header className="bg-dark text-white text-center" style={{ fontSize: '1.2rem' }}>
                                    {project.title}
                                </Card.Header>
                                <Card.Body className="text-center">
                                    <Card.Text className="mb-3">{project.description}</Card.Text>
                                    {project.type === 'kakao' ? (
                                        <Button variant='warning' className="text-white" onClick={() => window.open(project.link, '_blank')}>
                                            {project.title}
                                        </Button>
                                    ) : (
                                        <Button variant='info' className="text-white" onClick={this.copyToClipboard}>
                                            icurfer@gmail.com
                                        </Button>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}

export default App;
