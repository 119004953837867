import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NAV from './components/Navbar';
import FOOTER from "./components/Footer/FOOTER";
import Home from './pages/HOME';
import About from './pages/ABOUT';
import Items from './boardItems/Items';
import ItemDetail from './boardItems/ItemDetail';
import ItemsForm from './boardItems/ItemsForm';
// *** Board Components ***
import BoardForm from './pages/Board/BoardForm';
import BoardEdit from './pages/Board/BoardEdit';
import BoardList from './pages/Board/BoardList';
import BoardDetail from './pages/Board/BoardDetail';
// memberBoards 컴포넌트 추가 - 2024-07-26
import MemberBoards from './boardMember/MemberBoards';
import MemberBoardDetail from './boardMember/MemberBoardDetail';
import MemberBoardForm from './boardMember/MemberBoardForm';
import MemberBoardEdit from './boardMember/MemberBoardEdit';
// WordPressAutomation
import UrlList from './components/WordPressAuto/UrlList';
import UrlForm from './components/WordPressAuto/UrlForm';
import UrlDetail from './components/WordPressAuto/UrlDetail';
import UrlEdit from './components/WordPressAuto/UrlEdit';

// Management Account Tools
import SignUp from './accounts/SignUp';
import UserEditPass from './accounts/UserEditPass';
import Login from './pages/LOGIN';
import './css/theme-color.css'; // 테마관리 CSS 파일 추가 - 2024-08-11
import Demo from './test_page/templates/demo';
// Kubernetes_add_on
import Grafana from './kubernetesAddOn/Grafana';
// etc
import BlockCrash from './game/BlockCrash';

class App extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const grade = localStorage.getItem('grade'); // grade 추가 - 2024-07-27
    const defaultTenantId = localStorage.getItem('defaultTenantId'); // default_tenant_id 추가 - 2024-07-27
    this.state = {
      isAuthenticated: !!token, // 토큰이 있는지 확인하여 인증 상태 설정
      user: username, // 로컬 스토리지에서 username을 가져와 초기 상태 설정
      grade: grade, // 로컬 스토리지에서 grade을 가져와 초기 상태 설정
      defaultTenantId: defaultTenantId, // 로컬 스토리지에서 가져옴
    };
    
    // 게시판 변수 정의
    this.boardPostProps = {
      isAuthenticated: this.state.isAuthenticated,
      app_title: "Dev History",
      app_desc: "React / Django",
      apiEndpoint: "post_list",
      apiMinioBucket: "itstant-posts-md-img",
      listPath: "posts",
      formPath: "posts-create",
      itemsPerPage: 12,
    };

    this.boardPythonProps = {
      isAuthenticated: this.state.isAuthenticated,
      app_title: "Python Study",
      app_desc: "Python",
      apiEndpoint: "board_python_list",
      apiMinioBucket: "icurfer-python-md-img",
      listPath: "python",
      formPath: "python-create",
      itemsPerPage: 12,
    };

    this.boardDdochiProps = {
      isAuthenticated: this.state.isAuthenticated,
      app_title: "demo project",
      app_desc: "demo",
      apiEndpoint: "board_ddochi_list",
      apiMinioBucket: "icurfer-ddochi-md-img",
      listPath: "ddochi",
      formPath: "ddochi-create",
      itemsPerPage: 12,
    };

    this.boardToyProps = {
      isAuthenticated: this.state.isAuthenticated,
      app_title: "FrontEnd Toy Project",
      app_desc: "React",
      apiEndpoint: "board_toy_list",
      apiMinioBucket: "icurfer-toy-md-img",
      listPath: "toy",
      formPath: "toy-create",
      itemsPerPage: 12,
    };

    this.boardEbookProps = {
      isAuthenticated: this.state.isAuthenticated,
      app_title: "Ebook List",
      app_desc: "작성 중인 전자책 목록 입니다.",
      apiEndpoint: "board_ebook_list",
      apiMinioBucket: "icurfer-ebook-md-img",
      listPath: "ebook",
      formPath: "ebook-create",
      itemsPerPage: 12,
    };

    // wp-automation
    this.wpAutomationProps = {
      isAuthenticated: this.state.isAuthenticated,
      appt_title: "WordPress Create Post",
      app_desc: "WP 자동화 프로젝트",
      apiEndpoint: "article_url",
      listPath: "wp-url",
      formPath: "wp-url-registry",
      itemsPerPage: 12,
    }

  }

  // 로그인 상태를 업데이트하는 메서드
  setAuthenticated = (isAuthenticated, username = null, grade = null, defaultTenantId = null) => {
    this.setState({ 
      isAuthenticated, 
      user: isAuthenticated ? username : null, // 'isAuthenticated'가 'true'일 때만 'username'을 설정
      grade: isAuthenticated ? grade : null, // 'isAuthenticated'가 'true'일 때만 'grade'을 설정
      defaultTenantId: isAuthenticated ? defaultTenantId : null, // 'isAuthenticated'가 'true'일 때만 'defaultTenantId'를 설정
  }); // 수정된 부분 - 2024-07-28
    if (!isAuthenticated) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('grade');
      localStorage.removeItem('defaultTenantId');
    }
  };

  render() {
    return (
      <Router>
        <div>
          <NAV isAuthenticated={this.state.isAuthenticated} user={this.state.user} grade={this.state.grade} defaultTenantId={this.state.defaultTenantId} setAuthenticated={this.setAuthenticated} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/items" element={<Items isAuthenticated={this.state.isAuthenticated} />} />
            <Route path="/items/:id" element={<ItemDetail isAuthenticated={this.state.isAuthenticated} />} />
            <Route path="/items-reg" element={<ItemsForm isAuthenticated={this.state.isAuthenticated} />} />

            <Route path="/posts" element={<BoardList {...this.boardPostProps} />} />
            <Route path="/posts/:id" element={<BoardDetail {...this.boardPostProps} />} />
            <Route path="/posts-create" element={<BoardForm {...this.boardPostProps} />} />
            <Route path="/posts/:id/edit" element={<BoardEdit {...this.boardPostProps} />} />
            <Route path="/python" element={<BoardList {...this.boardPythonProps} />} />
            <Route path="/python/:id" element={<BoardDetail {...this.boardPythonProps} />} />
            <Route path="/python-create" element={<BoardForm {...this.boardPythonProps} />} />
            <Route path="/python/:id/edit" element={<BoardEdit {...this.boardPythonProps} />} />
            <Route path="/ddochi" element={<BoardList {...this.boardDdochiProps} />} />
            <Route path="/ddochi/:id" element={<BoardDetail {...this.boardDdochiProps} />} />
            <Route path="/ddochi-create" element={<BoardForm {...this.boardDdochiProps} />} />
            <Route path="/ddochi/:id/edit" element={<BoardEdit {...this.boardDdochiProps} />} />
            <Route path="/toy" element={<BoardList {...this.boardToyProps} />} />
            <Route path="/toy/:id" element={<BoardDetail {...this.boardToyProps} />} />
            <Route path="/toy-create" element={<BoardForm {...this.boardToyProps} />} />
            <Route path="/toy/:id/edit" element={<BoardEdit {...this.boardToyProps} />} />
            <Route path="/ebook" element={<BoardList {...this.boardEbookProps} />} />
            <Route path="/ebook/:id" element={<BoardDetail {...this.boardEbookProps} />} />
            <Route path="/ebook-create" element={<BoardForm {...this.boardEbookProps} />} />
            <Route path="/ebook/:id/edit" element={<BoardEdit {...this.boardEbookProps} />} />

            <Route path="/wp-url" element={<UrlList {...this.wpAutomationProps} />} />
            <Route path="/wp-url/:id" element={<UrlDetail {...this.wpAutomationProps} />} />
            <Route path="/wp-url-registry" element={<UrlForm {...this.wpAutomationProps} />} />
            <Route path="/wp-url/:id/edit" element={<UrlEdit {...this.wpAutomationProps} />} />
            
            <Route path="/memberboards" element={<MemberBoards isAuthenticated={this.state.isAuthenticated} grade={this.state.grade} />} />
            <Route path="/memberboards/:id" element={<MemberBoardDetail isAuthenticated={this.state.isAuthenticated} />} />
            <Route path="/memberboards-create" element={<MemberBoardForm isAuthenticated={this.state.isAuthenticated} />} />
            <Route path="/memberboards/:id/edit" element={<MemberBoardEdit isAuthenticated={this.state.isAuthenticated} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/user_edit_pass" element={<UserEditPass isAuthenticated={this.state.isAuthenticated} />} /> {/* 회원 정보 수정 페이지 라우팅 추가 */}
            <Route path="/login" element={<Login setAuthenticated={this.setAuthenticated} />} />
            {/* <Route path="/minio" element={<Minio />} /> */}
            <Route path="/demo" element={<Demo />} />
            <Route path="/grafana" element={<Grafana />} />
            <Route path="/blockCrash" element={<BlockCrash />} />
          </Routes>
          <FOOTER />
        </div>
      </Router>
      
    );
  }
}

export default App;