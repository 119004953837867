import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './css/Home.css'; // CSS 파일 추가 - 2024-07-28
import PortFolicoMain from './PortFolioMain';
import PortFolicoCards from './PortFolioCards';
import Contact from '../../landings/contact';
// import Header from './landings/Header';
import BannerSlideImage from '../../landings/BannerSlideImage'

class Home extends Component {
  render() {
    return (
      <div>
        <Container fluid className="flex-grow-1 home-bg d-flex flex-column min-vh-100 pt-5">
          <BannerSlideImage></BannerSlideImage>
        </Container>
        <Container fluid className="flex-grow-1 home-bg d-flex flex-column min-vh-100 pt-5">
          <PortFolicoMain></PortFolicoMain>
          <PortFolicoCards></PortFolicoCards>
        </Container>
        <Container>
          <Contact></Contact>
        </Container>
      </div>
    );
  }
}

export default Home;
