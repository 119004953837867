import React, { Component } from 'react';
import './HeroSection.css';

class HeroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waveHeight: 300, // 파도 높이 초기값
    };
  }

  handleScroll = (event) => {
    const newWaveHeight = this.state.waveHeight + event.deltaY * 0.05;
    this.setState({ waveHeight: Math.max(newWaveHeight, 0) }); // 최소값 0
  }

  componentDidMount() {
    window.addEventListener('wheel', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleScroll);
  }

  render() {
    return (
      <div className="hero">
        <h1>Welcome to My Portfolio</h1>
        <p>Your tagline here</p>
        <div className="waves" style={{ height: `${this.state.waveHeight}px` }}>
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
        </div>
      </div>
    );
  }
}

export default HeroSection;
