import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import FOOTER from "../components/Footer/FOOTER";
import { Navigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password1: '',
            password2: '',
            default_tenant_id: '', // default_tenant_id 상태 추가
            errorMessage: '',
            redirect: false // 리다이렉트를 위한 상태 추가
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { username, email, password1, password2, default_tenant_id } = this.state;

        // 필요한 데이터만 서버에 전송
        const data = {
            username,
            email,
            password1,
            password2,
        };

        // default_tenant_id 값이 있을 때만 추가
        if (default_tenant_id) {
            data.default_tenant_id = default_tenant_id;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/accounts/registration/custom/`, data)
            .then(response => {
                console.log(response.data);
                // 성공적으로 회원가입이 완료된 경우, 리다이렉트를 위한 상태 업데이트
                this.setState({ redirect: true });
            })
            .catch(error => {
                console.error(error);
                this.setState({ errorMessage: '회원가입 중 오류가 발생했습니다.' });
            });
    };

    render() {
        const { username, email, password1, password2, default_tenant_id, errorMessage } = this.state;

        if (this.state.redirect) {
            // 리다이렉트 상태가 true인 경우 메인 페이지로 리다이렉트
            return <Navigate to="/login" />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                {/* <NAV></NAV> */}
                <BannerInfo title="Sign-Up" desc="본 사이트의 회원 가입은 2024년 07월 15일 부터 테스트 목적으로 가입시 이름, 메일 만 수집하고 있으나 회원가입을 비권고 드립니다."></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row className="justify-content-md-center">
                        <Col md={4} lg={4}>
                            {/* 두 번째 섹션의 내용 */}
                        </Col>
                        <Col md={4} lg={4}>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>UserName</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="이름 입력"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>이메일 주소</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        placeholder="이메일주소 (비밀번호 찾기 등 본인 확인용)"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword1">
                                    <Form.Label>비밀번호</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password1"
                                        value={password1}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword2">
                                    <Form.Label>비밀번호 확인</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password2"
                                        value={password2}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="default_tenant_id">
                                    <Form.Label>Tenant ID(현재 기능x)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="default_tenant_id"
                                        value={default_tenant_id}
                                        onChange={this.handleChange}
                                        placeholder="TenantID 입력 (선택 사항)" // 선택 사항으로 필드 설명 추가
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-4">
                                    Sign Up
                                </Button>
                            </Form>
                        </Col>

                        <Col md={4} lg={4}>
                            {/* 세 번째 섹션의 내용 */}
                        </Col>
                    </Row>
                </Container>
                <FOOTER></FOOTER>
            </div>
        );
    }
}

export default SignUp;