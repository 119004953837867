import React, { Component } from 'react';
import './css/PortFolioMain.css'; // CSS 파일 추가 - 2024-07-28
import { Container, Row, Col } from 'react-bootstrap';

class PortfolioMain extends Component {

  render() {
    return (
      <Container className='portFolioMainContainer'>
        <Row>
          <Col md={12}>
            <p className='portFolioMainSubtitle'>
              React / Django / Kubernetes
            </p>
            <h1 className='portFolioTitle'>
              OpenSource Engineering
            </h1>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PortfolioMain;