import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import { Container, Table, Button, Pagination, Row, Col } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';

const custom_map = {
    api_contents_list: 'member_board_list',
    api_minio_bucket: 'icurfer-member-board-md-img',
    contents_list_path: 'memberboards',
    contents_form_path: 'memberboards-create',
};

class MemberBoards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            currentPage: 1,
            totalPages: 1,
            searchQuery: "",
        };
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    handleSearchSubmit = () => {
        this.fetchPosts(1, this.state.searchQuery);
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSearchSubmit();
        }
    }

    componentDidMount() {
        const { isAuthenticated, grade } = this.props;

        // 사용자가 로그인되어 있고, grade가 'gold'일 때만 게시글을 가져옵니다. - 2024-07-28
        if (isAuthenticated && grade === 'gold') {
            this.fetchPosts(this.state.currentPage);
        }
    }

    fetchPosts(page, query = "") {
        axios.get(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/?page=${page}&search=${query}`)
            .then(response => {
                this.setState({
                    posts: response.data.results,
                    currentPage: page,
                    totalPages: Math.ceil(response.data.count / 10),
                });
            })
            .catch(error => {
                console.error('There was an error fetching the blog posts!', error);
            });
    }

    handlePageChange = (page) => {
        this.fetchPosts(page);
    }

    truncateContent = (content) => {
        return content.length > 10 ? content.substring(0, 10) + "..." : content;
    }

    formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        const [month, day, year] = date.toLocaleDateString('en-US', options).split('/');
        return `${year}.${month}.${day}.`;
    }

    render() {
        const { posts, currentPage, totalPages, searchQuery } = this.state;
        const { isAuthenticated, grade } = this.props;

        // 로그인되지 않았거나 grade가 'gold'가 아니면 접근을 차단 - 2024-07-28
        if (!isAuthenticated || grade !== 'gold') {
            return <Navigate to="/" />; // 홈 페이지로 리다이렉트
        }


        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title="For Members" desc="멤버쉽 전용 게시물" header_className="posts_background py-5"></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                <Row className="mb-2 justify-content-end">
                        <Col className="d-flex col-2">
                            <div className='input-group justify-content-end'>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                    onKeyDown={this.handleKeyPress} // 엔터 키 핸들러 추가: 2024-07-30
                                    placeholder="Search posts"
                                    className='form-control'
                                />
                                <Button onClick={this.handleSearchSubmit} variant="primary" className='btn-navy'><i class="fa-solid fa-magnifying-glass"></i></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th>Hook Text</th>
                                        <th>Created At</th>
                                        <th>Author</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {posts.map(post => (
                                        <tr key={post.id}>
                                            <td>{post.id}</td>
                                            <td>
                                                <Link to={`/${custom_map.contents_list_path}/${post.id}`}>{post.title}</Link>
                                            </td>
                                            <td>{post.hook_text}</td>
                                            <td>{this.formatDate(post.created_at)}</td>
                                            <td>{post.author_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={4} className="d-flex justify-content-center">
                            <Pagination>
                                <Pagination.First onClick={() => this.handlePageChange(1)} disabled={currentPage === 1} />
                                <Pagination.Prev onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                {[...Array(totalPages)].map((_, index) => (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === currentPage}
                                        onClick={() => this.handlePageChange(index + 1)}
                                        className={index + 1 === currentPage ? 'btn-navy' : ''}  // 스타일 클래스 추가: 2024-07-31
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => this.handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                                <Pagination.Last onClick={() => this.handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                            </Pagination>
                        </Col>
                        <Col md={4} className="d-flex justify-content-end">
                            {isAuthenticated && grade === 'gold' && ( // 조건부 렌더링 추가 - 2024-07-28
                                <Link to={`/${custom_map.contents_form_path}`}>
                                    <Button variant="primary" className='btn-navy'><i class="fas fa-pen" aria-hidden="true"></i> Create</Button>
                                </Link>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default MemberBoards;
