import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const custom_map = {
    api_contents_list: 'items_list',
    api_minio_bucket: process.env.REACT_APP_minio_items_md_img, // 마크다운용
    api_minio_img_bucket: process.env.REACT_APP_minio_items_img, // 카드용
    contents_list_path: 'items',
    contents_form_path: 'items-reg',
};

const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        const navigate = useNavigate();
        return <Component {...props} params={params} navigate={navigate} />;
    };
};

class ItemDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
            presignedUrl: '', // presigned URL을 저장할 상태 추가 - 2024-08-11
        };
    }

    componentDidMount() {
        const { id } = this.props.params;

        axios.get(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/${id}/`)
            .then(response => {
                const post = response.data;
                this.updateImageUrls(post).then(updatedContent => {
                    post.content = updatedContent;
                    this.setState({ post }, this.fetchPresignedUrl); // Post가 설정된 후 Presigned URL 가져오기 - 2024-08-11
                });
            })
            .catch(error => {
                console.error('There was an error fetching the post!', error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.urlRefreshInterval);
    }

    updateImageUrls = async (post) => {
        const imageRegex = /!\[.*\]\((.*)\)/g;
        const matches = [...post.content.matchAll(imageRegex)];
        const updatedContentPromises = matches.map(async (match) => {
            const originalUrl = match[1];
            const key = originalUrl.split('/').slice(-1)[0].split('?')[0];
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${custom_map.api_minio_bucket}/${key}/`);
                return { originalUrl, signedUrl: response.data.url };
            } catch (error) {
                console.error('Error fetching signed URL:', error);
                return { originalUrl, signedUrl: originalUrl };
            }
        });

        const updatedUrls = await Promise.all(updatedContentPromises);
        let updatedContent = post.content;
        updatedUrls.forEach(({ originalUrl, signedUrl }) => {
            updatedContent = updatedContent.replace(originalUrl, signedUrl);
        });
        return updatedContent;
    }

    fetchPresignedUrl = async () => {
        const { post } = this.state;
        if (post && post.obs_path) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${post.obs_path}/`);
                // const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${custom_map.api_minio_bucket}/${post.obs_path}/`);
                this.setState({ presignedUrl: response.data.url }); // presigned URL 설정 - 2024-08-11
                console.log(response.data.url); // presigned URL 설정 - 2024-08-11

            } catch (error) {
                console.error('Error fetching presigned URL:', error);
            }
        }
    }

    handleDelete = () => {
        const { id } = this.props.params;

        axios.delete(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/${id}/`)
            .then(() => {
                this.props.navigate(`/${custom_map.contents_list_path}`);
            })
            .catch(error => {
                console.error('There was an error deleting the post!', error);
            });
    }

    handleDownload = async () => {
        try {
            // Presigned URL로부터 Blob 데이터를 가져옴
            const response = await axios.get(this.state.presignedUrl, {
                responseType: 'blob',
            });

            // Blob URL을 생성하고 링크 요소를 생성
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const title = this.state.post.title; // 게시글 제목
            const { post } = this.state; // 확장자를 가져와야된다.
            
            const fileExtension = post.obs_path.split('.').pop(); // 확장자만 추출

            link.href = url;

            // 원래 이미지 파일 이름을 사용하거나, 다운로드할 파일 이름을 지정
            link.setAttribute('download', `${title}.${fileExtension}`);

            // 링크 클릭을 시뮬레이션하여 다운로드를 실행
            document.body.appendChild(link);
            link.click();

            // 링크를 제거하여 메모리 해제를 수행
            document.body.removeChild(link);
        } catch (error) {
            console.error('File download error:', error);
        }
    };


    render() {
        const { post, presignedUrl } = this.state;
        const { isAuthenticated } = this.props;

        if (!post) return <div>Loading...</div>;

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title="Item Details" header_className="posts_background py-5" desc="Detailed view of the post"></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <Card className="mb-4">
                                <Card.Header as="h2">{post.title}</Card.Header>
                                <Card.Subtitle className="mb-2 text-muted bg-light p-2">
                                    {post.tags && post.tags.length > 0 && (
                                        <div className="mt-3">
                                            <div className="d-flex flex-wrap">
                                                {post.tags.map((tag, index) => (
                                                    <span key={index} className="badge tag-color me-2 mb-2">{tag}</span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Card.Subtitle>
                                <Card.Body>
                                    <Card.Text>
                                        <ReactMarkdown
                                            components={{
                                                code({ node, inline, className, children, ...props }) {
                                                    const match = /language-(\w+)/.exec(className || '');
                                                    return !inline && match ? (
                                                        <SyntaxHighlighter
                                                            style={atomDark}
                                                            language={match[1]}
                                                            PreTag="div"
                                                            {...props}
                                                        >
                                                            {String(children).replace(/\n$/, '')}
                                                        </SyntaxHighlighter>
                                                    ) : (
                                                        <code className={className} {...props}>
                                                            {children}
                                                        </code>
                                                    );
                                                },
                                                img({ node, ...props }) { // 이미지 스타일 수정 - 2024-08-07
                                                    return <img style={{ maxWidth: '100%' }} {...props} alt="" />;
                                                }
                                            }}
                                        >
                                            {post.content}
                                        </ReactMarkdown>
                                    </Card.Text>
                                    <hr />
                                    <p className="text-muted">Created at: {new Date(post.created_at).toLocaleString()}</p>
                                    <p className="text-muted">Updated at: {new Date(post.updated_at).toLocaleString()}</p>
                                    {/* Presigned URL 표시 - 2024-08-11 */}
                                    {presignedUrl && (
                                        <div className="mt-3">
                                            <Button
                                                variant="primary"
                                                onClick={this.handleDownload}
                                            >
                                                파일 다운로드
                                            </Button>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={1}>
                            {isAuthenticated && (
                                <div className="fixed-actions"> {/* 고정된 액션 버튼 컨테이너 - 2024-08-07 */}
                                    <Link to={`/${custom_map.contents_list_path}`}>
                                        <Button variant="dark" className='w-100 mb-2'><i class="fa-solid fa-list"></i></Button> {/* 버튼 간격 조정 - 2024-08-07 */}
                                    </Link>
                                    <Link to={`/${custom_map.contents_form_path}`}>
                                        <Button variant="dark" className='w-100 mb-2'><i class="fa-solid fa-plus"></i></Button> {/* 버튼 간격 조정 - 2024-08-07 */}
                                    </Link>
                                    <Link to={`/${custom_map.contents_list_path}/${post.id}/edit`}> {/* 버튼 간격 조정 - 2024-08-07 */}
                                        <Button variant="dark" className='w-100 mb-2'><i class="fa-regular fa-pen-to-square"></i></Button>
                                    </Link>
                                    <Link>
                                        <Button variant="danger" onClick={this.handleDelete} className='w-100 mb-2'><i class="fa-solid fa-trash"></i></Button>
                                    </Link>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(ItemDetail);
