import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap"; // 2023-08-18: Card 컴포넌트 추가
import '../css/game.css'

// 테트로미노 모양 정의
const TETROMINOS = {
    I: [
        [0, "I", 0, 0],
        [0, "I", 0, 0],
        [0, "I", 0, 0],
        [0, "I", 0, 0],
    ],
    O: [
        ["O", "O"],
        ["O", "O"],
    ],
    T: [
        [0, "T", 0],
        ["T", "T", "T"],
        [0, 0, 0],
    ],
    L: [
        [0, 0, "L"],
        ["L", "L", "L"],
        [0, 0, 0],
    ],
    J: [
        ["J", 0, 0],
        ["J", "J", "J"],
        [0, 0, 0],
    ],
    Z: [
        ["Z", "Z", 0],
        [0, "Z", "Z"],
        [0, 0, 0],
    ],
    S: [
        [0, "S", "S"],
        ["S", "S", 0],
        [0, 0, 0],
    ],
};

// 랜덤 테트로미노 생성
const getRandomTetromino = () => {
    const tetrominos = "IOTLJSZ";
    const random = tetrominos[Math.floor(Math.random() * tetrominos.length)];
    return TETROMINOS[random];
};

// 초기 보드 생성
const createBoard = () => {
    return Array.from({ length: 20 }, () => Array(10).fill(0));
};

// Board 컴포넌트
class Board extends React.Component {
    render() {
        return (
            <Container className="profile-section mt-5">
                <div style={{ display: "grid", gridTemplateColumns: `repeat(10, 30px)` }}>
                    {this.props.grid.map((row, y) =>
                        row.map((cell, x) => (
                            <div
                                key={`${x}-${y}`}
                                style={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: cell ? "blue" : "white",
                                    border: "1px solid black",
                                }}
                            ></div>
                        ))
                    )}
                </div>
            </Container>
        );
    }
}

// Game 컴포넌트
class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            board: createBoard(),
            piece: getRandomTetromino(),
            piecePosition: { x: 4, y: 0 },
            gameOver: false,
            gameStarted: false, // 게임 시작 여부
            dropSpeed: 1000, // 2023-08-18: 블록이 떨어지는 초기 속도
            level: 1, // 2023-08-18: 레벨을 통한 속도 제어
            score: 0, // 2023-08-18: 점수 상태 추가
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (this.state.gameOver || !this.state.gameStarted) return;

        // 스페이스바 눌렀을 때 기본 동작 방지
        if (event.key === " ") {
            event.preventDefault();
        }

        switch (event.key) {
            case "ArrowLeft":
                this.movePiece(-1);
                break;
            case "ArrowRight":
                this.movePiece(1);
                break;
            case "ArrowDown":
                this.movePieceDown();
                break;
            case "ArrowUp":
                this.rotatePiece();
                break;
            case " ":
                this.dropPiece(); // 스페이스바로 블록 즉시 떨어뜨리기
                break;
            default:
                break;
        }
    };

    startGame = () => {
        this.setState(
            {
                board: createBoard(),
                piece: getRandomTetromino(),
                piecePosition: { x: 4, y: 0 },
                gameOver: false,
                gameStarted: true, // 게임 시작 상태
                dropSpeed: 1000, // 게임 시작 시 속도 초기화
                level: 1, // 게임 시작 시 레벨 초기화
                score: 0, // 2023-08-18: 게임 시작 시 점수 초기화
            },
            () => {
                clearInterval(this.interval); // 이전 인터벌이 남아 있을 수 있으므로 초기화
                this.interval = setInterval(this.gameLoop, this.state.dropSpeed);
            }
        );
    };

    increaseSpeed = () => {
        const { level, dropSpeed } = this.state;

        // 2023-08-18: 레벨과 속도 조정 로직
        if (level < 10) {
            this.setState(
                {
                    level: level + 1,
                    dropSpeed: dropSpeed * 0.9, // 속도를 10%씩 빠르게
                },
                () => {
                    clearInterval(this.interval);
                    this.interval = setInterval(this.gameLoop, this.state.dropSpeed);
                }
            );
        }
    };

    gameLoop = () => {
        if (!this.state.gameOver) {
            this.movePieceDown();
        }
    };

    movePiece = (direction) => {
        const { x, y } = this.state.piecePosition;
        if (!this.checkCollision(x + direction, y)) {
            this.setState({ piecePosition: { x: x + direction, y } });
        }
    };

    movePieceDown = () => {
        const { x, y } = this.state.piecePosition;
        if (!this.checkCollision(x, y + 1)) {
            this.setState({ piecePosition: { x, y: y + 1 } });
        } else {
            this.fixPiece();
        }
    };

    rotatePiece = () => {
        const { piece } = this.state;
        const rotatedPiece = piece[0].map((_, i) => piece.map((row) => row[i])).reverse();
        const { x, y } = this.state.piecePosition;
        if (!this.checkCollision(x, y, rotatedPiece)) {
            this.setState({ piece: rotatedPiece });
        }
    };

    checkCollision = (x, y, piece = this.state.piece) => {
        for (let row = 0; row < piece.length; row++) {
            for (let col = 0; col < piece[row].length; col++) {
                if (piece[row][col]) {
                    if (
                        y + row >= 20 || // 바닥에 닿을 때
                        x + col < 0 || // 좌측 벽에 닿을 때
                        x + col >= 10 || // 우측 벽에 닿을 때
                        (y + row >= 0 && this.state.board[y + row][x + col] !== 0) // 블록이 다른 블록과 충돌할 때
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    fixPiece = () => {
        const { board, piece, piecePosition } = this.state;
        const newBoard = board.map((row) => row.slice());

        piece.forEach((row, y) => {
            row.forEach((cell, x) => {
                if (cell && y + piecePosition.y >= 0) {
                    newBoard[y + piecePosition.y][x + piecePosition.x] = cell;
                }
            });
        });

        this.removeFullRows(newBoard);
    };

    removeFullRows = (newBoard) => {
        const clearedBoard = newBoard.filter((row) => row.some((cell) => cell === 0));
        const rowsCleared = 20 - clearedBoard.length;
        const emptyRows = Array.from({ length: rowsCleared }, () => Array(10).fill(0));

        if (rowsCleared > 0) {
            this.setState(
                (prevState) => ({
                    board: [...emptyRows, ...clearedBoard],
                    piece: getRandomTetromino(),
                    piecePosition: { x: 4, y: 0 },
                    score: prevState.score + rowsCleared * 100, // 점수 계산 (한 줄당 100점)
                    level: prevState.level + 1, // 2023-08-18: 한 줄이 제거될 때 레벨 증가
                    dropSpeed: prevState.dropSpeed * 0.9, // 2023-08-18: 레벨이 오를 때 속도 증가
                }),
                () => {
                    clearInterval(this.interval);
                    this.interval = setInterval(this.gameLoop, this.state.dropSpeed);
                }
            );
        } else {
            this.setState({
                board: [...emptyRows, ...clearedBoard],
                piece: getRandomTetromino(),
                piecePosition: { x: 4, y: 0 },
            }, () => {
                if (this.checkCollision(this.state.piecePosition.x, this.state.piecePosition.y)) {
                    this.setState({ gameOver: true, gameStarted: false });
                    clearInterval(this.interval);
                    if (!this.alertShown) {
                        alert("Game Over");
                        this.alertShown = true;
                    }
                }
            });
        }
    };

    dropPiece = () => {
        const { x } = this.state.piecePosition;
        let y = this.state.piecePosition.y;

        while (!this.checkCollision(x, y + 1)) {
            y += 1;
        }

        this.setState({ piecePosition: { x, y } }, this.fixPiece);
    };

    render() {
        const { board, piece, piecePosition, gameOver, gameStarted, level, score } = this.state;
        const grid = board.map((row) => [...row]);

        piece.forEach((row, y) => {
            row.forEach((cell, x) => {
                if (cell && y + piecePosition.y >= 0) {
                    grid[y + piecePosition.y][x + piecePosition.x] = cell;
                }
            });
        });

        return (
            <Container className="profile-section mt-5 d-flex flex-column min-vh-100">
                <Row className="justify-content-center">
                    <Col xs={12} md={3} className="d-flex flex-column align-items-center">
                        {/* 왼쪽 섹션: 레벨 표시 */}
                        <Card className="mb-3" style={{ width: "100%" }}> {/* 2023-08-18: 레벨 카드 추가 */}
                            <Card.Body>
                                <Card.Title>Level</Card.Title>
                                <Card.Text>{level}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} md={6} className="d-flex flex-column align-items-center">
                        {/* 중앙 섹션: 게임 */}
                        <h1 className="text-center">BlockCrash</h1>
                        {!gameStarted && (
                            <Button variant="primary" onClick={this.startGame}>
                                Start Game
                            </Button>
                        )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center", // 중앙 정렬
                            }}
                        >
                            {gameStarted && <Board grid={grid} />}
                        </div>
                        {gameOver && <h2 className="text-center">Game Over</h2>}
                    </Col>

                    <Col xs={12} md={3} className="d-flex flex-column align-items-center">
                        {/* 오른쪽 섹션: 점수 표시 */}
                        <Card className="mb-3" style={{ width: "100%" }}> {/* 2023-08-18: 점수 카드 추가 */}
                            <Card.Body>
                                <Card.Title>Score</Card.Title>
                                <Card.Text>{score}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Game;
