import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import HeroSection from './HeroSection'
import PortfolioSection from './PortfolioSection'
import AboutSection from './AboutSection'

class RotatingBackground extends Component {
  render() {
    return (
      <div>
        <Container>
            <HeroSection></HeroSection>
            <PortfolioSection></PortfolioSection>
            <AboutSection></AboutSection>
        </Container>
      </div>
    );
  }
}

export default RotatingBackground;
