import React, { Component } from 'react';
import './css/PortFolioCards.css'; // CSS 파일 추가 - 2024-07-28
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

class PortfolioCards extends Component {

  render() {
    const projects = [
      {
        id: 1,
        title: "인터넷 강의",
        description: "지식공유 프로젝트",
        link: "https://www.youtube.com/@it-stant/playlists"
      },
      {
        id: 2,
        title: "구현 아키텍처 및 플로우",
        sub_title: "개발 히스토리",
        description: "지금 보시는 사이트 개발 프로젝트입니다. Kubernetes, FE, BE",
        link: "/about",
        sub_link: "/posts"
      },
      {
        id: 3,
        title: "https://toy.itstant.com",
        description: "웹 토이 프로젝트",
        link: "https://toy.itstant.com"
      },
      {
        id: 4,
        title: "gitea",
        description: "공개 중인 소스코드 입니다. 사설 git Repository에 공개중입니다.",
        link: "https://gitea.itstant.com/icurfer"
      },
    ];

    return (
      <Container>
        <Row>
          <h2 className="pt-5 text-white">Contents</h2>
          {projects.map(project => (
            <Col key={project.id} md={3}>
              <Card className='shadow-lg portFolioCard_background'>
                <Card.Body className='portFolioCard portFolioCard_background'>
                  <Card.Text>{project.description}</Card.Text>
                  <Button className='shadow-lg portFolioCard__title' onClick={() => window.open(project.link, '_blank')}>{project.title}</Button>
                  {project.sub_title && (
                    <Button className='shadow-lg portFolioCard__title' onClick={() => window.open(project.sub_link, '_blank')}>
                      {project.sub_title}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default PortfolioCards;
