import React, { Component } from 'react';
import axios from 'axios';
import NAV from "../../components/Navbar";
import BannerInfo from "../../components/Banner/BannerInfo";
import FOOTER from "../../components/Footer/FOOTER";
import { Navigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            errorMessage: '',
            redirect: false, // 리다이렉트를 위한 상태 추가
            csrftoken: '' // CSRF 토큰을 위한 상태 추가
        };
    }

    componentDidMount() {
        // 컴포넌트가 마운트될 때 CSRF 토큰을 상태에 저장
        this.setState({ csrftoken: this.getCookie('csrftoken') });
    }

    // CSRF 토큰을 가져오는 함수 추가
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { username, password } = this.state;

        axios.post(`${process.env.REACT_APP_API_URL}/accounts/login/`, {
            username,
            password
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.getCookie('csrftoken') // CSRF 토큰 포함
            }
        })
            .then(response => {
                console.log(response.data);
                // 로그인 성공 시 토큰을 로컬 스토리지에 저장하고 인증 상태 업데이트
                localStorage.setItem('token', response.data.key);
                this.getUserInfo(response.data.key);  // 토큰으로 사용자 정보 가져오기
                console.log("username check : " + this.getUserInfo(response.data.key));
            })
            .catch(error => {
                console.error(error);
                this.setState({ errorMessage: '로그인 중 오류가 발생했습니다.' });
            });
    };

    getUserInfo = (token) => {
        axios.get(`${process.env.REACT_APP_API_URL}/accounts/user-info/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                const username = response.data.username;
                const grade = response.data.grade;
                const default_tenant_id = response.data.default_tenant_id;
                localStorage.setItem('username', username);
                localStorage.setItem('grade', grade); // grade 저장 - 2024-07-27
                localStorage.setItem('defaultTenantId', default_tenant_id); // default_tenant_id 저장 - 2024-07-27

                console.log("login.js check : " + grade);
                console.log("login.js check : " + default_tenant_id);
                this.props.setAuthenticated(true, username, grade, default_tenant_id); // 상태 즉시 변경 - 2024-07-28
                this.setState({ redirect: true });
            })
            .catch(error => {
                console.error(error);
                this.setState({ errorMessage: '사용자 정보를 가져오는 중 오류가 발생했습니다.' });
            });
    };

    render() {
        const { username, password, errorMessage, redirect } = this.state;

        if (redirect) {
            return <Navigate to="/" />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                <NAV></NAV>
                <BannerInfo title="Sign In" desc="안녕하세요."></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row className="justify-content-md-center">
                        <Col md={4} lg={4}>
                        </Col>
                        <Col md={4} lg={4}>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>UserName</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="이름 입력"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Label>비밀번호</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-4">
                                    Login
                                </Button>
                            </Form>
                        </Col>
                        <Col md={4} lg={4}>
                        </Col>
                    </Row>
                </Container>
                <FOOTER></FOOTER>
            </div>
        );
    }
}

export default Login;