import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './BannerInfo.css'

class BannerInfo extends Component {
  render() {
    return (
      <header className="banner_background py-5">
        <Container className="px-4 px-lg-5 my-5">
          <Row className="justify-content-center text-center text-white">
            <Col lg={8}>
              <h1 className="display-4 fw-bolder">{this.props.title}</h1>
              <p className="lead fw-normal text-white-50 mb-0">{this.props.desc}</p>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default BannerInfo;
