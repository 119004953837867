import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import FOOTER from "../components/Footer/FOOTER";
import { Navigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

class UserEditPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            errorMessage: '',
            successMessage: '',
            redirect: false,
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { oldPassword, newPassword, confirmPassword } = this.state;

        if (newPassword !== confirmPassword) {
            this.setState({ errorMessage: '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.' });
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            this.setState({ errorMessage: '로그인 토큰이 없습니다. 다시 로그인 해주세요.' });
            return;
        }

        axios.put(`${process.env.REACT_APP_API_URL}/accounts/change-password/`, {
            old_password: oldPassword,
            new_password: newPassword
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            this.setState({
                successMessage: '비밀번호가 성공적으로 변경되었습니다.',
                errorMessage: '',
                redirect: true, // 비밀번호 변경 후 리다이렉트
            });
        })
        .catch(error => {
            console.error(error);
            this.setState({
                errorMessage: '비밀번호 변경 중 오류가 발생했습니다.',
                successMessage: ''
            });
        });
    };

    render() {
        const { oldPassword, newPassword, confirmPassword, errorMessage, successMessage, redirect } = this.state;

        if (redirect) {
            return <Navigate to="/" />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title="Change Password" desc="비밀번호를 변경하세요."></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row className="justify-content-md-center">
                        <Col md={4} lg={4}></Col>
                        <Col md={4} lg={4}>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formOldPassword">
                                    <Form.Label>현재 비밀번호</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="현재 비밀번호 입력"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formNewPassword">
                                    <Form.Label>새 비밀번호</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="새 비밀번호 입력"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword">
                                    <Form.Label>비밀번호 확인</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="새 비밀번호 확인"
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-4">
                                    비밀번호 변경
                                </Button>
                            </Form>
                        </Col>
                        <Col md={4} lg={4}></Col>
                    </Row>
                </Container>
                <FOOTER></FOOTER>
            </div>
        );
    }
}

export default UserEditPass;
