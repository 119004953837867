import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";

class Profile extends Component {
    render() {
        return (
            <Card>
                <Card.Header as="h2">
                    Profile
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4} className="d-flex flex-column justify-content-center align-items-center">
                            <h2 className="profile-title">icurfer</h2>
                        </Col>
                        <Col md={8} className="text-start">
                            <h5>경력</h5>
                            <span>2023.03 ~ 재직중..  | N사(계열사) | 클라우드 엔지니어(Cloud Native 환경 구축)</span><br />
                            <span>2022.08 ~ 2023.02 | 국비지원교육(클라우드 엔지니어 양성과정)</span><br />
                            <span>2021.07 ~ 2022.07 | P사 (의류제조) |경영기획(경영정보 시스템 구축 기획, POC 진행)</span><br />
                            <span>2018.06 ~ 2021.06 | W사 (가전제조) | RPA 도입, RPA 업무 자동화 개발, VBA 개발, 소규모 서버 운용, DLP, ERP, MES, PLM, GW 관리 및 운용, 사내 Help 데스크, 개인정보 처리방침 제정, 내부관리계획 수립, 소프트웨어 라이선스 관리 등.</span><br />
                            <span>2016.02 ~ 2018.05 | W사 (가전제조) | 구매관리</span><br />
                            <br />
                            <h6>E-Mail : icurfer@gmail.com</h6>
                            
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

export default Profile;
