import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../Banner/BannerInfo";
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead'; // 태그 기능
import 'react-bootstrap-typeahead/css/Typeahead.css';

// 2024-07-22 withRouter 함수를 정의
const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        return <Component {...props} params={params} />;
    };
};

class UrlEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            url: '',
            desc: '',
            author: '',
            tags: [],
            errorMessage: '',
            redirect: false,
            redirectToDetail: false, 
            csrftoken: ''
        };
        this.tagInput = React.createRef(); // 태그 인풋을 참조하기 위한 ref 생성
    }

    componentDidMount() {
        this.setState({ csrftoken: this.getCookie('csrftoken') });
        this.getPostData();
    }

    // CSRF 토큰 가져오기
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    getPostData = () => {
        const { id } = this.props.params;
        const { apiEndpoint } = this.props;

        axios.get(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/${id}/`)
            .then(response => {
                const { title, url, desc, author, tags } = response.data;
                this.setState({ title, url, desc, author, tags: tags.map(tag => ({ label: tag })) });
            })
            .catch(error => {
                console.error('There was an error fetching the post!', error);
                this.setState({ errorMessage: 'Failed to fetch post data.' });
            });
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleTagChange = (selected) => {
        this.setState({
            tags: selected,
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = e.target.value.trim();
            if (newTag) {
                this.setState(state => ({
                    tags: [...state.tags, { label: newTag }],
                }));
                this.tagInput.current.clear();
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { title, url, desc, author, tags } = this.state;
        const { apiEndpoint } = this.props;
        const { id } = this.props.params;

        axios.put(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/${id}/`, {
            title,
            url,
            desc,
            author,
            tags: tags.map(tag => tag.label),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken
            }
        })
            .then(response => {
                console.log(response.data);
                this.setState({ redirect: true });
            })
            .catch((error) => {
                console.error('There was an error updating the post!', error.response ? error.response.data : error.message);
                this.setState({ errorMessage: 'Failed to update post.' });
            });
    };

    handleCancel = () => {
        this.setState({ redirectToDetail: true });
    }

    render() {
        const { title, url, desc, tags, errorMessage, redirect, redirectToDetail } = this.state;
        const { app_title, app_desc, listPath, isAuthenticated } = this.props;
        const { id } = this.props.params;

        if (redirect) {
            return <Navigate to={`/${listPath}/`} />;
        }

        if (redirectToDetail) {
            return <Navigate to={`/${listPath}/${id}`} />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title={app_title} header_className="posts_background py-5" desc={app_desc} />
                <Container className="flex-grow-1 mt-4">
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={title}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formUrl">
                            <Form.Label>Hook</Form.Label>
                            <Form.Control
                                type="text"
                                name="url"
                                value={url}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTags">
                            <Form.Label>Tags</Form.Label>
                            <Typeahead
                                id="tags"
                                multiple
                                allowNew
                                newSelectionPrefix="Add a new tag: "
                                options={[]}
                                selected={tags}
                                onChange={this.handleTagChange}
                                onKeyDown={this.handleKeyDown}
                                ref={this.tagInput}
                                placeholder="Add tags"
                            />
                        </Form.Group>
                        <Form.Group controlId="formDesc">
                            <Form.Label>desc</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                name="desc"
                                value={desc}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        {isAuthenticated && (
                            <div className="d-flex justify-content-between mt-4">
                                <Button variant="primary" type="submit">
                                    Update Post
                                </Button>
                                <Button variant="secondary" type="button" onClick={this.handleCancel}>
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </Form>
                    <br />
                </Container>
            </div>
        );
    }
}

export default withRouter(UrlEdit);
