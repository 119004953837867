import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from 'react-bootstrap-typeahead'; // 추가: 2024-08-06 - react-bootstrap-typeahead 사용
import 'react-bootstrap-typeahead/css/Typeahead.css'; // 추가: 2024-08-06 - 스타일 임포트

const custom_map = {
    api_contents_list: 'member_board_list',
    api_minio_bucket: 'icurfer-member-board-md-img',
    contents_list_path: 'memberboards',
    contents_form_path: 'memberboards-create',
};

const mdParser = new MarkdownIt();

// 2024-07-22 withRouter 함수를 이 파일에 정의
const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        return <Component {...props} params={params} />;
    };
};

class MemberBoardEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            hook_text: '',
            content: '',
            author: '',
            tags: [], // 추가: 2024-08-06 - 태그 상태 추가
            errorMessage: '',
            redirect: false,
            redirectToDetail: false, // 수정된 부분: 포스트 상세 페이지로 리다이렉트 설정 - 2024-07-22
            csrftoken: ''
        };
        this.editorInstance = React.createRef(); // 에디터 인스턴스를 참조하기 위한 ref 생성 - 2024-07-31
        this.tagInput = React.createRef(); // 태그 인풋을 참조하기 위한 ref 생성 - 2024-08-06
    }

    componentDidMount() {
        this.setState({ csrftoken: this.getCookie('csrftoken') });
        this.getPostData();
        // "paste" 이벤트가 발생할 때 handlePaste라는 함수를 실행하도록 설정하는 이벤트 리스너 - 2024-07-24
        document.addEventListener('paste', this.handlePaste);
    }

    componentWillUnmount() {
        document.removeEventListener('paste', this.handlePaste);
    }

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    getPostData = () => {
        const { id } = this.props.params;

        axios.get(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/${id}/`)
            .then(response => {
                const { title, hook_text, content, author, tags } = response.data; // 태그 추가 - 2024-08-06
                this.setState({ title, hook_text, content, author, tags: tags.map(tag => ({ label: tag })) }); // 태그 상태 업데이트 - 2024-08-06
            })
            .catch(error => {
                console.error('There was an error fetching the post!', error);
                this.setState({ errorMessage: 'Failed to fetch post data.' });
            });
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleEditorChange = ({ text }) => {
        this.setState({ content: text });
    };

    handleTagChange = (selected) => { // 태그 변경 핸들러 추가 - 2024-08-06
        this.setState({
            tags: selected,
        });
    };

    handleKeyDown = (e) => { // 태그 입력 핸들러 추가 - 2024-08-06
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = e.target.value.trim();
            if (newTag) {
                this.setState(state => ({
                    tags: [...state.tags, { label: newTag }],
                }));
                this.tagInput.current.clear(); // 입력 필드 초기화
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { title, hook_text, content, author, tags } = this.state;
        const { id } = this.props.params;

        axios.put(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/${id}/`, {
            title,
            hook_text,
            content,
            author,
            tags: tags.map(tag => tag.label), // 태그 이름만 전송 - 2024-08-06
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken
            }
        })
            .then(response => {
                console.log(response.data);
                this.setState({ redirect: true });
            })
            .catch((error) => {
                console.error('There was an error updating the post!', error.response ? error.response.data : error.message);
                this.setState({ errorMessage: 'Failed to update post.' });
            });
    };

    handleCancel = () => {
        this.setState({ redirectToDetail: true }); // 수정된 부분: 포스트 상세 페이지로 리다이렉트 설정 - 2024-07-22
    }

    // 붙여넣기 핸들 - 2024-07-24
    handlePaste = async (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (const item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    const uniqueFileName = this.generateUniqueFileName(file.name);
                    const imageUrl = await this.uploadImage(file, uniqueFileName);
                    this.insertImageMarkdown(imageUrl);
                }
            }
        }
    }

    // 고유 파일명 생성 - 2024-07-24
    generateUniqueFileName = (originalName) => {
        const extension = originalName.split('.').pop();
        const date = new Date();
        const formattedDate = date.toISOString().slice(2, 10).replace(/-/g, '-'); // YY-MM-DD 형식 생성
        const uniqueName = `${formattedDate}-${uuidv4()}.${extension}`;
        return uniqueName;
    }

    // 이미지 업로드 2024-07-24
    uploadImage = async (file, uniqueFileName) => {
        try {
            // 1단계: 이미지 업로드를 위한 사전 서명된 URL 얻기
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-upload-url/${custom_map.api_minio_bucket}/${uniqueFileName}/`, {
                headers: {
                    'X-CSRFToken': this.state.csrftoken
                }
            });
            const uploadUrl = response.data.url;

            // 2단계: 사전 서명된 URL로 이미지 업로드
            await axios.put(uploadUrl, file, {
                headers: {
                    'Content-Type': file.type,
                    'X-CSRFToken': this.state.csrftoken
                }
            });

            // 3단계: 업로드된 이미지를 접근할 수 있는 URL 얻기
            try {
                const accessResponse = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${custom_map.api_minio_bucket}/${uniqueFileName}/`);
                const accessibleUrl = accessResponse.data.url;

                // 접근 가능한 URL을 상태에 저장
                this.setState({ imageUrl: accessibleUrl });

                // 접근 가능한 URL 반환
                return accessibleUrl;
            } catch (error) {
                console.error('이미지 URL을 가져오는 데 실패했습니다:', error);
                this.setState({ errorMessage: '이미지 URL을 가져오는 중 오류가 발생했습니다.' });
                return null;
            }
        } catch (error) {
            console.error('이미지 업로드에 실패했습니다:', error);
            this.setState({ errorMessage: '이미지 업로드에 실패했습니다.' });
            return null;
        }
    }

    // 마크다운에 이미지 넣기 - 2024-07-31
    insertImageMarkdown = (url) => {
        if (url && this.editorInstance.current) {
            // const { content } = this.state;
            const editor = this.editorInstance.current;
            const currentSelection = editor.getSelection();
            const currentText = editor.getMdValue();

            const textBeforeCursor = currentText.substring(0, currentSelection.start);
            const textAfterCursor = currentText.substring(currentSelection.end);

            const newContent = `${textBeforeCursor}\n![image](${url})\n${textAfterCursor}`;
            editor.setText(newContent); // 에디터 내용 업데이트
        }
    }

    render() {
        const { title, hook_text, content, tags, errorMessage, redirect, redirectToDetail } = this.state; // 태그 추가 - 2024-08-06
        const { id } = this.props.params;

        if (redirect) {
            return <Navigate to={`/${custom_map.contents_list_path}/`} />;
        }

        if (redirectToDetail) { // 수정된 부분: 포스트 상세 페이지로 리다이렉트 설정 - 2024-07-22
            return <Navigate to={`/${custom_map.contents_list_path}/${id}`} />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title="Edit Contents" desc="Edit your contents"></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={title}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formHook">
                            <Form.Label>Hook</Form.Label>
                            <Form.Control
                                type="text"
                                name="hook_text"
                                value={hook_text}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTags">
                            <Form.Label>Tags</Form.Label>
                            <Typeahead
                                id="tags"
                                multiple
                                allowNew
                                newSelectionPrefix="Add a new tag: "
                                options={[]}
                                selected={tags}
                                onChange={this.handleTagChange}
                                onKeyDown={this.handleKeyDown} // 키 다운 이벤트 핸들러 추가 - 2024-08-06
                                ref={this.tagInput} // ref 추가 - 2024-08-06
                                placeholder="Add tags"
                            />
                        </Form.Group>
                        <Form.Group controlId="formContent">
                            <Form.Label>Content</Form.Label>
                            <MdEditor
                                ref={this.editorInstance} // 에디터 인스턴스 참조 - 2024-07-31
                                style={{ height: '500px' }}
                                renderHTML={(text) => mdParser.render(text)}
                                onChange={this.handleEditorChange}
                                value={content}
                                className="dark-theme" // 사용자 정의 클래스 추가
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-between mt-4">
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                            <Button variant="secondary" type="button" onClick={this.handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                    <br />
                </Container>
            </div>
        );
    }
}

export default withRouter(MemberBoardEdit); // 수정된 부분: withRouter 적용 - 2024-07-22