import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../components/Banner/BannerInfo";
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import ImageUpload from '../components/ImageUpload'; // ImageUpload 컴포넌트 임포트 - 2024-07-22
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from 'react-bootstrap-typeahead'; // 2024-08-06 태그 기능을 위해 추가
import 'react-bootstrap-typeahead/css/Typeahead.css'; // 2024-08-06 태그 기능을 위해 추가

const custom_map = {
    api_contents_list: 'items_list',
    api_minio_bucket: process.env.REACT_APP_minio_items_md_img, // 마크다운용
    api_minio_img_bucket: process.env.REACT_APP_minio_items_img, // 카드용
    contents_list_path: 'items',
    contents_form_path: 'items-reg',
};

const mdParser = new MarkdownIt();

class ItemsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            hook_text: '',
            content: '',
            author: '', // 작성자 등록 - 2024-07-20
            tags: [], // 추가: 2024-08-06 - 태그 상태 추가
            errorMessage: '',
            redirect: false, // 리다이렉트를 위한 상태 추가
            csrftoken: '', // CSRF 토큰을 위한 상태 추가
            obs_path: '', // obs_path 추가 - 2024-08-11 2차 수정
            url: '',
        };
        this.editorInstance = React.createRef(); // 에디터 인스턴스를 참조하기 위한 ref 생성 - 2024-07-31
        this.tagInput = React.createRef(); // 태그 인풋을 참조하기 위한 ref 생성
    }

    componentDidMount() {
        this.setState({ csrftoken: this.getCookie('csrftoken') });
        this.getUserInfo(); // 컴포넌트가 마운트될 때 사용자 정보를 가져옴

        document.addEventListener('paste', this.handlePaste);
    }

    componentWillUnmount() {
        document.removeEventListener('paste', this.handlePaste);
    }

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    getUserInfo = () => {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/accounts/user-info/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
            .then(response => {
                const user_id = response.data.user_id;
                this.setState({ author: user_id });
            })
            .catch(error => {
                console.error(error);
                this.setState({ errorMessage: '사용자 정보를 가져오는 중 오류가 발생했습니다.' });
            });
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleEditorChange = ({ text }) => {
        this.setState({ content: text });
    };

    handleTagChange = (selected) => {
        this.setState({
            tags: selected.map(tag => tag.label),
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = e.target.value.trim();
            if (newTag) {
                this.setState(state => ({
                    tags: [...state.tags, newTag],
                }));
                this.tagInput.current.clear(); // 입력 필드 초기화
            }
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { title, hook_text, content, author, tags, url } = this.state;

        // 이미지 업로드를 먼저 실행
        const imageUploadResult = await this.imageUploadRef.uploadImageToMinIO();

        if (!imageUploadResult.success) {
            this.setState({ errorMessage: '이미지 첨부가 필요합니다. 업로드에 실패했습니다.' });
            return;
        }

        // obs_path를 이미지 업로드 결과로 설정 - 2024-08-11 2차 수정
        // this.setState({ obs_path: `${custom_map.api_minio_img_bucket}/${imageUploadResult.fileName}` });
        const obs_path = `${custom_map.api_minio_img_bucket}/${imageUploadResult.fileName}`;

        console.log('Tags before submission:', tags);
        
        axios.post(`${process.env.REACT_APP_API_URL}/api/${custom_map.api_contents_list}/`, {
            title,
            hook_text,
            content,
            author,
            tags,
            url,
            obs_path, // obs_path 추가 - 2024-08-11 2차 수정
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken // CSRF 토큰 포함
            }
        })
            .then(response => {
                console.log(response.data);
                this.setState({ redirect: true });
            })
            .catch((error) => {
                console.error('There was an error registration!', error.response ? error.response.data : error.message);
                this.setState({ errorMessage: 'Failed to item registration.' });
            });
    };

    handleCancel = () => {
        this.setState({ redirect: true });
    }

    handlePaste = async (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (const item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    const uniqueFileName = this.generateUniqueFileName(file.name);
                    const imageUrl = await this.uploadImage(file, uniqueFileName);
                    this.insertImageMarkdown(imageUrl);
                }
            }
        }
    }

    generateUniqueFileName = (originalName) => {
        const extension = originalName.split('.').pop();
        const date = new Date();
        const formattedDate = date.toISOString().slice(2, 10).replace(/-/g, '-'); // YY-MM-DD 형식 생성
        const uniqueName = `${formattedDate}-${uuidv4()}.${extension}`;
        return uniqueName;
    }

    uploadImage = async (file, uniqueFileName) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-upload-url/${custom_map.api_minio_bucket}/${uniqueFileName}/`, {
                headers: {
                    'X-CSRFToken': this.state.csrftoken
                }
            });
            const uploadUrl = response.data.url;

            await axios.put(uploadUrl, file, {
                headers: {
                    'Content-Type': file.type,
                    'X-CSRFToken': this.state.csrftoken
                }
            });

            try {
                const accessResponse = await axios.get(`${process.env.REACT_APP_API_URL}/minio/get-presigned-url/${custom_map.api_minio_bucket}/${uniqueFileName}/`);
                const accessibleUrl = accessResponse.data.url;
                this.setState({ imageUrl: accessibleUrl });
                return accessibleUrl;
            } catch (error) {
                console.error('이미지 URL을 가져오는 데 실패했습니다:', error);
                this.setState({ errorMessage: '이미지 URL을 가져오는 중 오류가 발생했습니다.' });
                return null;
            }
        } catch (error) {
            console.error('이미지 업로드에 실패했습니다:', error);
            this.setState({ errorMessage: '이미지 업로드에 실패했습니다.' });
            return null;
        }
    }

    insertImageMarkdown = (url) => {
        if (url && this.editorInstance.current) {
            const editor = this.editorInstance.current;
            const currentSelection = editor.getSelection();
            const currentText = editor.getMdValue();

            const textBeforeCursor = currentText.substring(0, currentSelection.start);
            const textAfterCursor = currentText.substring(currentSelection.end);

            const newContent = `${textBeforeCursor}\n![image](${url})\n${textAfterCursor}`;
            editor.setText(newContent); // 에디터 내용 업데이트
        }
    }

    render() {
        const { title, hook_text, content, tags, url, errorMessage, redirect } = this.state;

        if (redirect) {
            return <Navigate to={`/${custom_map.contents_list_path}/`} />;
        }

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title="상품 등록" desc="상품 등록" header_className="posts_background py-5"></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={title}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formHook">
                            <Form.Label>Hook</Form.Label>
                            <Form.Control
                                type="text"
                                name="hook_text"
                                value={hook_text}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formTags">
                            <Form.Label>Tags</Form.Label>
                            <Typeahead
                                id="tags"
                                multiple
                                allowNew
                                newSelectionPrefix="Add a new tag: "
                                options={[]}
                                selected={tags.map(tag => ({ label: tag }))}
                                onChange={this.handleTagChange}
                                onKeyDown={this.handleKeyDown}
                                ref={this.tagInput}
                                placeholder="Add tags"
                            />
                        </Form.Group>
                        <Form.Group controlId="formUrl">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="url"
                                value={url}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <ImageUpload ref={(ref) => this.imageUploadRef = ref} csrftoken={this.state.csrftoken} /> {/* ImageUpload 컴포넌트 사용 - 2024-08-11 2차 수정 */}
                        <Form.Group controlId="formContent">
                            <Form.Label>Content</Form.Label>
                            <MdEditor
                                ref={this.editorInstance}
                                style={{ height: '500px' }}
                                renderHTML={(text) => mdParser.render(text)}
                                onChange={this.handleEditorChange}
                                value={content}
                                className="dark-theme"
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="primary" type="submit" className='me-2'>
                                <i class="fas fa-pen" aria-hidden="true"></i> Registration
                            </Button>
                            <Button variant="secondary" type="button" onClick={this.handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                    <br />
                </Container>
            </div>
        );
    }
}

export default ItemsForm;