import React, { Component } from "react";
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import './NAV.css'; // CSS 파일 추가 - 2024-07-28

class NAV extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showServiceDropdown: false, // Service 드롭다운 상태 추가 - 2024-08-16
            showPostDropdown: false, // Post 드롭다운 상태 추가 - 2024-08-16
            showPrivateDropdown: false // Private 드롭다운 상태 추가 - 2024-10-04
        };
        this.handleLogout = this.handleLogout.bind(this); // 메서드 바인딩
        this.handleToggleServiceDropdown = this.handleToggleServiceDropdown.bind(this); // 메서드 바인딩 - 2024-08-16
        this.handleTogglePostDropdown = this.handleTogglePostDropdown.bind(this); // 메서드 바인딩 - 2024-08-16
        this.handleTogglePrivateDropdown = this.handleTogglePrivateDropdown.bind(this); // 메서드 바인딩 - 2024-10-04
        this.handleMouseLeaveServiceDropdown = this.handleMouseLeaveServiceDropdown.bind(this); // 메서드 바인딩 - 2024-08-16
        this.handleMouseLeavePostDropdown = this.handleMouseLeavePostDropdown.bind(this); // 메서드 바인딩 - 2024-08-16
        this.handleMouseLeavePrivateDropdown = this.handleMouseLeavePrivateDropdown.bind(this); // 메서드 바인딩 - 2024-10-04
        this.handleMouseEnter = this.handleMouseEnter.bind(this); // 메서드 바인딩 - 2024-08-11
    }

    handleLogout() {
        this.props.setAuthenticated(false);
    }

    handleToggleServiceDropdown() {
        this.setState(prevState => ({
            showServiceDropdown: !prevState.showServiceDropdown,
            showPostDropdown: false,
            showPrivateDropdown: false // Service 드롭다운을 열 때 다른 드롭다운을 닫음 - 2024-10-04
        }));
    }

    handleTogglePostDropdown() {
        this.setState(prevState => ({
            showPostDropdown: !prevState.showPostDropdown,
            showServiceDropdown: false,
            showPrivateDropdown: false // Post 드롭다운을 열 때 다른 드롭다운을 닫음 - 2024-10-04
        }));
    }

    handleTogglePrivateDropdown() {
        this.setState(prevState => ({
            showPrivateDropdown: !prevState.showPrivateDropdown,
            showServiceDropdown: false,
            showPostDropdown: false // Private 드롭다운을 열 때 다른 드롭다운을 닫음 - 2024-10-04
        }));
    }

    handleMouseLeaveServiceDropdown() {
        this.leaveTimeout = setTimeout(() => {
            this.setState({ showServiceDropdown: false });
        }, 1000);
    }

    handleMouseLeavePostDropdown() {
        this.leaveTimeout = setTimeout(() => {
            this.setState({ showPostDropdown: false });
        }, 1000);
    }

    handleMouseLeavePrivateDropdown() {
        this.leaveTimeout = setTimeout(() => {
            this.setState({ showPrivateDropdown: false }); // Private 드롭다운 마우스 리브 이벤트 - 2024-10-04
        }, 1000);
    }

    handleMouseEnter() {
        if (this.leaveTimeout) {
            clearTimeout(this.leaveTimeout);
        }
    }

    render() {
        const { isAuthenticated, user, grade, defaultTenantId } = this.props;
        const { showServiceDropdown, showPostDropdown, showPrivateDropdown } = this.state;

        return (
            <Navbar fixed="top" expand="lg" className="navbar-custom mb-3" variant="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src={`${process.env.PUBLIC_URL}/ddochi.png`}
                            alt="Logo"
                            className="navbar-logo"
                        />
                        {`${process.env.REACT_APP_brand_name}`}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" />
                    <Navbar.Collapse id="navbarSupportedContent" className="justify-content-end">
                        <Nav className="mr-auto p-2">
                            {/* Service 드롭다운 */}
                            <Dropdown
                                show={showServiceDropdown}
                                onClick={this.handleToggleServiceDropdown}
                                onMouseLeave={this.handleMouseLeaveServiceDropdown}
                                onMouseEnter={this.handleMouseEnter}
                            >
                                <Dropdown.Toggle as={Nav.Link}>
                                    Service
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/blockCrash">MiniGame-BlockCrash</Dropdown.Item>
                                    <Dropdown.Item href="/ebook">전자책</Dropdown.Item>
                                    <Dropdown.Item href="#" className="disabled">컨설팅(준비중)</Dropdown.Item>
                                    <Dropdown.Item href="#" className="disabled">기술지원(준비중)</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* Post 드롭다운 */}
                            <Dropdown
                                show={showPostDropdown}
                                onClick={this.handleTogglePostDropdown}
                                onMouseLeave={this.handleMouseLeavePostDropdown}
                                onMouseEnter={this.handleMouseEnter}
                            >
                                <Dropdown.Toggle as={Nav.Link}>
                                    Post
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/posts">DevHistory</Dropdown.Item>
                                    <Dropdown.Item href="/python">PythonStudy</Dropdown.Item>
                                    <Dropdown.Item href="/ddochi">prj_ddochi</Dropdown.Item>
                                    <Dropdown.Item href="/toy">prj_toy</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* Private 드롭다운 - 2024-10-04 */}
                            {isAuthenticated && grade === 'gold' &&  (
                                <Dropdown
                                    show={showPrivateDropdown}
                                    onClick={this.handleTogglePrivateDropdown}
                                    onMouseLeave={this.handleMouseLeavePrivateDropdown}
                                    onMouseEnter={this.handleMouseEnter}
                                >
                                    <Dropdown.Toggle as={Nav.Link}>
                                        Private
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/memberboards/">board</Dropdown.Item>
                                        <Dropdown.Item href="/wp-url/">WP-Automation</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            <Nav.Link href="/items/">Items</Nav.Link>
                            <Nav.Link href="/about/">About</Nav.Link>
                            {!isAuthenticated && <Nav.Link href="/SignUp/">Sign Up</Nav.Link>}
                            {!isAuthenticated ? (
                                <Nav.Link href="/login/">Log In</Nav.Link>
                            ) : (
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        {user} / {grade} / {defaultTenantId}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/user_edit_pass">비밀번호 변경</Dropdown.Item>
                                        <Dropdown.Item onClick={this.handleLogout}>Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default NAV;
