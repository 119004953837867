import React, { Component } from 'react';

class GrafanaDashboard extends Component {
  render() {
    const grafanaUrl = `${process.env.REACT_APP_GRAFANA_DASHBOARD_URL}`;

    console.log('test');
    console.log(grafanaUrl);
    
    return (
      

      <div style={{ height: '100vh', width: '100%' }}>
        <iframe
          src={grafanaUrl}
          width="100%"
          height="100%"
          style={{ border: 'none' }} // 테두리 제거
          title="Grafana Dashboard"
        ></iframe>
      </div>
    );
  }
}

export default GrafanaDashboard;
