import React, { Component } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class ImageBanner extends Component {
  render() {
    return (
      <Container fluid className="p-0">
        <Carousel interval={2000} fade>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/wave1.jpg`}
              alt="First slide"
              style={{ height: '80vh', objectFit: 'cover' }}
            />
            <Carousel.Caption>
              <h3>학습 및 테스트 합니다.</h3>
              <p>지속적으로 IT 관련 분야를 학습, 테스트 합니다.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/wave2.jpg`}
              alt="Second slide"
              style={{ height: '80vh', objectFit: 'cover' }}
            />
            <Carousel.Caption>
              <h3>기록합니다.</h3>
              <p>학습 및 테스트 하는 과정 및 결과를 기록하고있습니다.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/wave3.jpg`}
              alt="Third slide"
              style={{ height: '80vh', objectFit: 'cover' }}
            />
            <Carousel.Caption>
              <h3>공유합니다.</h3>
              <p>학습 및 테스트한 내용을 공유합니다.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
    );
  }
}

export default ImageBanner;
