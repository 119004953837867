import React, { Component } from 'react';
import axios from 'axios';
import BannerInfo from "../Banner/BannerInfo";
import { Container, Table, Button, Pagination, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class UrlList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            currentPage: 1,
            totalPages: 1,
            searchQuery: "", // 검색어 상태 추가: 2024-07-29
            csrftoken: "", // CSRF 토큰 상태 추가: 2024-10-06
        };
    }

    // 검색 기능 추가: 2024-07-29
    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    // 검색 기능 추가: 2024-07-29
    handleSearchSubmit = () => {
        this.fetchPosts(1, this.state.searchQuery);
    }

    // 엔터 키로 검색 동작 추가: 2024-07-30
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSearchSubmit();
        }
    }

    componentDidMount() {
        this.fetchPosts(this.state.currentPage);
        this.setState({ csrftoken: this.getCookie('csrftoken') });
    }

    // Django에서 사용하는 CSRF 쿠키를 가져오는 유틸리티 함수: 2024-10-06
    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    // Pod 생성 API 호출 함수 추가: 2024-10-06
    handleCreatePod = () => {
        const { csrftoken } = this.state;
        
        const postData = {
            pod_name: "api-test-pod",
        };

        axios.post(`${process.env.REACT_APP_API_URL}/api/kubernetes-pods/create-pod/`, postData, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken // CSRF 토큰 포함: 2024-10-06
            }
        })
        .then(response => {
            console.log('Pod created successfully:', response.data);
        })
        .catch(error => {
            console.error('There was an error creating the pod:', error);
        });
    }

    fetchPosts(page, query = "") {
        const { apiEndpoint, itemsPerPage } = this.props; // this.props로 접근하도록 수정
        axios.get(`${process.env.REACT_APP_API_URL}/api/${apiEndpoint}/?page=${page}&search=${query}`)
            .then(response => {
                this.setState({
                    posts: response.data.results,
                    currentPage: page,
                    totalPages: Math.ceil(response.data.count / `${itemsPerPage}`),
                });
            })
            .catch(error => {
                console.error('There was an error fetching the blog posts!', error);
            });
    }

    handlePageChange = (page) => {
        this.fetchPosts(page);
    }

    truncateContent = (content) => {
        return content.length > 10 ? content.substring(0, 10) + "..." : content;
    }

    formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        const [month, day, year] = date.toLocaleDateString('en-US', options).split('/');
        return `${year}.${month}.${day}.`;
    }

    render() {
        const { posts, currentPage, totalPages, searchQuery } = this.state;
        const { app_title, app_desc, listPath, formPath, isAuthenticated } = this.props; // this.props로 접근하도록 수정

        return (
            <div className="d-flex flex-column min-vh-100">
                <BannerInfo title={app_title} desc={app_desc}></BannerInfo>
                <Container className="flex-grow-1 mt-4">
                    <Row className="mb-2 justify-content-end">
                        <Col className="d-flex col-2">
                            <div className='input-group justify-content-end'>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                    onKeyDown={this.handleKeyPress} // 엔터 키 핸들러 추가: 2024-07-30
                                    placeholder="Search posts"
                                    className='form-control'
                                />
                                <Button onClick={this.handleSearchSubmit} variant="primary" className='btn-navy'><i className="fa-solid fa-magnifying-glass"></i></Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th>URL</th>
                                        <th>DESC</th>
                                        <th>Created At</th>
                                        <th>Author</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {posts.map(post => (
                                        <tr key={post.id}>
                                            <td>{post.id}</td>
                                            <td>
                                                <Link to={`/${listPath}/${post.id}`}>{post.title}</Link>
                                            </td>
                                            <td>{post.url}</td>
                                            <td>{post.desc}</td>
                                            <td>{this.formatDate(post.created_at)}</td>
                                            <td>{post.author_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            
                        </Col>
                        <Col md={4} className="d-flex justify-content-center">
                            <Pagination>
                                <Pagination.First onClick={() => this.handlePageChange(1)} disabled={currentPage === 1} />
                                <Pagination.Prev onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                {[...Array(totalPages)].map((_, index) => (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === currentPage}
                                        onClick={() => this.handlePageChange(index + 1)}
                                        className={index + 1 === currentPage ? 'btn-navy' : ''}  // 스타일 클래스 추가: 2024-07-31
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => this.handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                                <Pagination.Last onClick={() => this.handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                            </Pagination>
                        </Col>
                        <Col md={4} className="d-flex justify-content-end">
                            {isAuthenticated && (
                                <div>
                                <Link to={`/${formPath}`}>
                                    <Button variant="primary" className='btn-navy'><i className="fas fa-pen" aria-hidden="true"></i> url 등록</Button>
                                </Link>
                            
                                <Button variant="success" onClick={this.handleCreatePod} className='btn-create-pod'><i className="fas fa-pen" aria-hidden="true"></i> Create Pod
                                </Button>
                            </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default UrlList;